import React, { Component } from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import LoginState from '../components/LoginState';
import { checkRouteForString } from '../helpers/utils';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.props.history ? this.props.history.location.pathname.split('/')[3] : '',
      lastPage: '',
      borrowerName: '',
      printView: checkRouteForString('conditionprint', this.props.history.location.pathname.toLowerCase())
    };
  }

  componentDidMount() {
    this.getBorrowerName();
  }

  componentDidUpdate() {
    if (this.state.lastPage === this.props.history.location.pathname)
      return;
    this.getBorrowerName();
  }

  async getBorrowerName() {
    const splitLength = this.props.history.location.pathname.split('/').length;
    var bName = '';
    if (splitLength >= 3) {
      try {
        var response = axios.get('/getborrowername', {
          params: { pFileID: this.props.history.location.pathname.split('/')[3] },
        });
        bName = (await response).data;
      }
      catch { bName = ''; }
    }
    this.setState({
      lastPage: this.props.history.location.pathname,
      key: splitLength >= 3 ? this.props.history.location.pathname.split('/')[3] : '',
      borrowerName: bName
    });
  }

  isActive(path) {
    return this.props.history.location.pathname.split('/').includes(path.replace("/", "")) ? "true" : "";
  }

  render() {
    return (
      <>
        <ul className="nav nav-pills flex-column mb-auto">
          <li data-bs-dismiss={this.props.dismiss ? "offcanvas" : ""}><Link to='/dashboard' className={`nav-link ${this.isActive('dashboard') ? 'active' : ''}`}><i className="bi-house me-2"></i>Dashboard</Link></li>
          <li data-bs-dismiss={this.props.dismiss ? "offcanvas" : ""}><Link to='/new-request' className={`nav-link ${this.isActive('/new-request') ? 'active' : ''}`}><i className="bi-plus-square me-2"></i>New Request</Link></li>
          <li data-bs-dismiss={this.props.dismiss ? "offcanvas" : ""}><Link to='/my-requests' className={`nav-link ${this.isActive('/my-requests') ? 'active' : ''}`}><i className="bi-table me-2"></i>My Requests</Link></li>
          {/* <li className="mb-1">
            <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0 w-100 collapsed nav-link" data-bs-toggle="collapse" data-bs-target="#resources" aria-expanded="true">
              <i className="bi-box me-2"></i>
              Resources
            </button>
            <div className="collapse" id="resources">
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-4" >
                <li data-bs-dismiss={this.props.dismiss ? "offcanvas" : ""}><Link to='/resources/forms' className={`nav-link ${this.isActive('forms') ? 'active' : ''}`}>Forms</Link></li>
                <li data-bs-dismiss={this.props.dismiss ? "offcanvas" : ""}><Link to='/resources/product-matrix' className={`nav-link ${this.isActive('product-matrix') ? 'active' : ''}`}>Matrix</Link></li>
                <li data-bs-dismiss={this.props.dismiss ? "offcanvas" : ""}><Link to='/tools/appraisal-finder' className={`nav-link ${this.isActive('appraisal-finder') ? 'active' : ''}`}>Appraisal Fee Finder</Link></li>
              </ul>
            </div>
          </li> */}
        </ul>
      </>
    )
  }
}

export default withRouter(Sidebar);