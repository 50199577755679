import styled from 'styled-components';

export const ErrorMessage = styled.div`
  text-align: center;
  color: red;
`;

export const SuccessMessage = styled.div`
  text-align: center;
  white-space: pre-wrap;
  color: green;
`;

export const DownloadLink = styled.span`
  color: ${p => p.theme.colors.primary500};
  cursor: pointer;
  text-decoration: underline;
  &: hover {
    color: ${p => p.theme.colors.primary700};
    text-decoration: underline;
  }
`;

export const LoginContainer = styled.div`
  background: linear-gradient(${p => `45deg, ${p.theme.colors.primary300}, ${p.theme.colors.primary100}`});
  border-radius: 4px;
  margin-top: 1rem;
  form {
    padding: 2rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    input {
      border: none;
      padding: 1rem;
      font-size: 1rem;
      background-color: ${p => p.theme.colors.white};
      margin-bottom: 1rem;
      outline: none;
    }
  }
`;

export const JWT_SECRET_KEY = 'prayer_reqs_key';