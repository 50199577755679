import { InlineBody, InlineCard, InlineHeader, InlineWrapper, InlineClose } from './PopupInlineElements';
import { LeftTD, RightTD, CenterTD, HeaderTable } from './LayoutComponents';
import React, { Component } from 'react';
import LoginState from './LoginState';

export default class PrayerList extends Component {

  componentDidMount() {}

  componentDidUpdate() {
    if(document.getElementById('innerContentDiv'))
      document.getElementById('contentDiv').innerHTML = this.props.description;
  }

  checkClose() {
    this.props.onClose();
  }

  isOwner() {
    return (this.props.selected && LoginState.displayName === this.props.selected.requestor);
  }

  render() {
    return this.props.show ? (
      <InlineWrapper onClick={() => this.checkClose()}>
        <InlineCard onClick={(e) => e.stopPropagation()}>
          <InlineHeader>
            <HeaderTable><tbody>
              <tr style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                <RightTD width="30px" style={{paddingLeft: '5px'}}>
                  <InlineClose>
                    <i className="bi bi-x-square" onClick={this.props.onClose}></i>
                  </InlineClose>
                </RightTD>
                <LeftTD style={{maxWidth: '75vw'}}>{this.props.selected.title}</LeftTD>
                <td style={{width: '75vw'}} />
                <RightTD style={{paddingRight: '10px'}}>{this.isOwner() && (<InlineClose onClick={this.props.onEdit}>[Edit]</InlineClose>)}</RightTD>
              </tr>
            </tbody></HeaderTable>
          </InlineHeader>
          
          <InlineBody>
            <div style={{paddingLeft: '10px', overflowY: 'auto'}}>
              <table>
                <tbody>
                  <tr>
                    <td>Requested By:</td>
                    <td width="10px" />
                    <td>{this.props.selected.requestor}</td>
                  </tr>
                  <tr>
                    <td>Request Date:</td>
                    <td width="10px" />
                    <td>{this.props.selected.requestDate}</td>
                  </tr>
                  <tr>
                    <td>Request Expires:</td>
                    <td width="10px" />
                    <td>{this.props.selected.expiration}</td>
                  </tr>
                </tbody>
              </table>
              {(this.props.selected && this.props.selected.status === "Urgent") && (
                <div style={{color:'red'}}>
                  <i className="bi bi-patch-exclamation-fill"></i> Urgent Request
                </div>
              )}
              <br/>
              {this.props.selected.description}
            </div>
          </InlineBody>

          <table height="75px"><tbody>
            <tr>
              <LeftTD width="30px" style={{paddingLeft: '10px'}} onClick={this.props.onPrev}>
                <i className="bi bi-caret-left-square"></i>
              </LeftTD>
              <CenterTD>{this.props.selected.position}</CenterTD>
              <RightTD width="30px" style={{paddingRight: '10px'}} onClick={this.props.onNext}>
                <i className="bi bi-caret-right-square"></i>
              </RightTD>
            </tr>
          </tbody></table>

        </InlineCard>
      </InlineWrapper>
    ) : null;
  }
}