import { useEffect, useState } from 'react';

export const STATUS_COLORS = {
  'Active': 'info',
  'Answered': 'success',
  'Canceled': 'danger',
  'Expired': 'danger',
  'Urgent': 'warning'
};

export const useFormFields = initialState => {
  const [fields, setValues] = useState(initialState);
  return [
    fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
};

export const capitalize = str => {
  if (!str) return;
  const regex = /(\b[a-z](?!\s))/g;
  return str.toLowerCase().replace(regex, x => x.toUpperCase());
};

export const formatCurrency = (amount, edit) => {
  // check to see that it's a valid number
  if (Number(amount) === Number.Nan) {
    return edit ? '' : '-';
  }
  const number = typeof amount === 'number' ? amount : Number(amount);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(number);
};

const formatNumber = (n) => {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const noCommas = (n) => {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(",", "");
}

export const unformatCurrency = (text) => {
  if (text === "") { return; }
  var returnVal = "";
  // check for decimal
  if (text.indexOf(".") >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = text.indexOf(".");

    // split number by decimal point
    var left_side = text.substring(0, decimal_pos);
    var right_side = text.substring(decimal_pos + 1);

    // remove comma from left side of number
    left_side = noCommas(left_side);

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    returnVal = left_side + (right_side.length > 0 ? ("." + right_side) : "");

  } else {
    // no decimal entered, remove commas
    returnVal = noCommas(text);
  }
  // send updated string to input
  return returnVal;
}

export const autoFormatCurrency = (text) => {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.
  if (text === "") { return; }
  var returnVal = "";

  // check for decimal
  if (text.indexOf(".") >= 0) {

    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = text.indexOf(".");

    // split number by decimal point
    var left_side = text.substring(0, decimal_pos);
    var right_side = text.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    returnVal = "$" + left_side + "." + right_side;

  } else {
    // no decimal entered, add commas to number and remove all non-digits
    returnVal = formatNumber(text);
    returnVal = returnVal.length > 0 ? ("$" + returnVal) : "";
  }
  // send updated string to input
  return returnVal;
}

export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '---';
};

export const checkRouteForString = (term, pathname) => pathname.includes(term);

export const base64ArrayBuffer = (arrayBuffer) => {
  var base64    = '';
  var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  var bytes         = new Uint8Array(arrayBuffer);
  var byteLength    = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength    = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048)   >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032)     >>  6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63;               // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder === 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3)   << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder === 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008)  >>  4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15)    <<  2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }
  
  return base64;
};

export const todaysDate = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth()+1;
  var yy = today.getFullYear().toString().slice(2);
  if(dd<10) { dd='0'+dd; }
  if(mm<10) { mm='0'+mm; }

  return mm + "/" + dd + "/" + yy;
}

export const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 60000); // check once every minute

    return () => clearInterval(interval);
  }, [countDownDate]);

  var countDownComplete = (countDown < 0);
  return [countDownComplete];
};