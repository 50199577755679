import React, {useEffect, useState} from 'react';

export default function Forms(props){
  const [forms, setForms] = useState(null);

  useEffect(() => {
    if(!forms) getForms()
  })

  const getForms = async () => {
    setForms([
      { name: "MTG Loss Payee Info", size: "51 kb", url: "https://PrayerReqs.com/forms/MTG Loss Payee.pdf" },
      { name: "MTG Condo Cert/Questionnaire - Limited Review", size: "239 kb", url: "https://PrayerReqs.com/forms/MTG Condo Questionnaire_LIMITED_09142021.pdf" },
      { name: "MTG Condo Cert/Questionnaire - Full Review", size: "299 kb", url: "https://PrayerReqs.com/forms/MTG Condo Questionnaire_FULL_09142021.pdf" },
      { name: "Form 4506-C", size: "57 kb", url: "https://PrayerReqs.com/forms/Form 4506-C.pdf" },
      { name: "Form SSA-89", size: "55 kb", url: "https://PrayerReqs.com/forms/Form SSA-89.pdf" },
      { name: "Borrower's Certification & Authorization", size: "15 kb", url: "https://PrayerReqs.com/forms/Borrower's Authorization_v2.pdf" },
      { name: "Patriot Act Disclosure", size: "15 kb", url: "https://PrayerReqs.com/forms/Patriot Act Disclosure.pdf" },
    ]);
  }

  const renderList = () => {
    if(!forms){
      return <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
    } else {
      return <div class="list-group">
        {forms.map(form => (
          <a href={form.url} key={form.name} target="_blank" rel="noopener noreferrer" class="list-group-item list-group-item-action d-flex align-items-center p-2">
            <i class="bi bi-download mx-3"></i>
            <span>
              <strong>{form.name}</strong>
              <small class="text-muted ms-2">
                {form.size}
              </small>
            </span>
          </a>
        ))}
      </div>
    }
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h4 >Forms</h4>
          {renderList()}
        </div>
      </div>
    </div>
  )
}