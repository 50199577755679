import React, {useEffect, useState} from 'react';
import axios from 'axios';

export default function ProductMatrix(props) {
  const [products, setProducts] = useState(null);

  useEffect(() => {
    if(!products) getProducts()
  })

  const getProducts = async () => {
    const response = axios.get('/getmatrixfilelist');
    try {
      var resultData = (await response).data;
      setProducts(resultData);
    }
    catch { console.log('Error fetching matrix files'); }
  }

  const openFile = async (fileName) => {
    var response = axios.get('/getMatrixFile', { params: { pFileName: fileName } });
    try {
      var resultData = (await response).data;
      var binary = atob(resultData);
      var array = [];
      for (var j=0; j<binary.length; j++) {
        array.push(binary.charCodeAt(j));
      }
      var file = new Blob([new Uint8Array(array)]);

      // this code below will download the file we selected
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    } 
    catch {
      this.setState({ errorMsg: 'Failed to retrieve file from server.' });
    }
  }

  const renderList = () => {
    if(!products){
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
        </div>
      );
    } else {
      return <div class="list-group">
      {products.map(prod => (
        <button key={prod.formName} onClick={()=> openFile(prod.fileName)} class="list-group-item list-group-item-action d-flex align-items-center p-2 px-3">
          <i class="bi bi-download me-3"></i>
          <span>
            <strong>{prod.formName}</strong>
            <small class="text-muted ms-2">
              {prod.size} kb
            </small>
          </span>
          <small class="text-muted ms-auto">
            {prod.lastUpdated}
          </small>
        </button>
      ))}
    </div>
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h4 >Product Matrix</h4>
          {renderList()}
        </div>
      </div>
    </div>
  )
}