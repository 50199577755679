import { ACTIONS, Session } from '../helpers/sessionContext';
// components
import { Link, useHistory } from 'react-router-dom';
import React, { useContext } from 'react';
import { checkRouteForString } from '../helpers/utils';
import Sidebar from '../components/SideBar';

const GlobalNavBar = ({ user }) => {
  const { dispatch } = useContext(Session);
  const history = useHistory();

  const logout = () => {
    dispatch({ type: ACTIONS.REMOVE_USER });
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    history.push('/');
  };

  const admin = () => {
    history.push('/admin');
  };

  if (checkRouteForString('conditionprint', history.location.pathname)) {
    return null;
  }

  return (
    <header className="bg-white position-fixed top-0 left-0 right-0 w-100 px-3 py-2 border-bottom d-flex justify-content-between align-items-center" style={{ zIndex: '99' }}>
      <Link to="/">
        <img src="/images/PrayerReqsLogo.png" alt="MTG logotype" width="150" />
      </Link>
      <button className="btn btn-light d-md-none rounded-circle" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
        <i className="bi bi-list"></i>
      </button>
      <div className="align-items-center d-none d-md-flex">
        {user && (
          <>
            <div className="dropdown">
              <button className="btn btn-text dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {user.imageUrl
                  ? <img src={user.imageUrl} alt="mdo" width="32" height="32" className="rounded-circle" />
                  : <i className="bi bi-person-circle"></i>}
              </button>
              <ul className="dropdown-menu">
                <li><p className="dropdown-item mb-0 disabled">{user.displayName}</p></li>
                <li><hr className="dropdown-divider" /></li>
                {user.userRole && user.userRole.toLowerCase().includes('admin') && (
                  <li><span className="dropdown-item" onClick={admin}>Admin</span></li>
                )}
                <li><span className="dropdown-item" onClick={logout}>Logout</span></li>
              </ul>
            </div>
          </>
        )}
      </div>
      <div className="offcanvas offcanvas-start navigation" id="offcanvasExample" aria-labelledby="offcanvasLabel">
        <div className="offcanvas-header">
        <img src="/images/PrayerReqsLogo.png" alt="MTG logotype" width="150" />
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <Sidebar dismiss={true} />
        </div>
      </div>
    </header>
  );
};

export default GlobalNavBar;
