import React from "react";
import Login from '../Login';
import styled from 'styled-components';

// STYLES

const LandingWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 0;
  background: linear-gradient(-30deg, ${p => p.bg ? p.theme.colors.primary100 : 'transparent'}, white, white);
  padding: 10vh 0;
`;
  
const LandingContainer = styled.div`
  max-width: 1440px;
  min-height: 0;
  height: auto;
  box-shadow: none;
  margin: 0 auto;
  background-color: transparent;
`;

// COMPONENTS

const Landing = () => {

  return (
    <>
      <LandingWrapper>
        <LandingContainer top="10%">
          <div className="row px-4">
            <div className="col-12 col-md-8">
              <h3 className="display-3">Pray For One Another</h3>
              <h6 className="text-secondary">Be anxious for nothing, but in everything by prayer and supplication, 
                with thanksgiving, let your requests be made known to God; and the peace of God, which surpasses all 
                understanding, will guard your hearts and minds through Christ Jesus.<br/>
                (Philippians 4:6-7)
              </h6>
            </div>
            <div className="col-12 col-md-4">
              <Login/>
            </div>
          </div>
        </LandingContainer>
      </LandingWrapper>
    </>
  );
};

export default Landing;