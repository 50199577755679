import React, { Component } from 'react';
import CanvasJSReact from '../libraries/canvasjs.react';
import LoginState from '../components/LoginState';
import { Link } from '../components/LayoutComponents';
import axios from 'axios';
import { STATUS_COLORS } from '../helpers/utils';
import { debounce } from 'lodash';
import PrayerList from '../components/PrayerList';

export default class Dashboard extends Component {
  
  constructor(props) {
    super(props);
    this.state = { 
      chartCount: [0,0,0,0],
      total: 0,
      searchQuery: '',
      fetching: true,
      viewAll: true,
      wholeList: [],
      requestList: [],
      lastSub: this.props.match.params.id,
      showList: false,
      selectedPR: [],
      selectedIndex: 0
    };

    this.FetchMyRequests();
  }

  componentDidUpdate() {
    if (this.state.lastSub !== this.props.match.params.id) {
      this.setState({ 
        requestList: this.getFilteredList(this.state.wholeList), 
        lastSub: this.props.match.params.id,
        viewAll: this.props.match.params.id.toLowerCase() !== "all"
      });
    }
  }

  async FetchMyRequests() {
    try {
      const response = axios.get('/FetchAllRequests', {
        params: { userEmail: LoginState.userId }
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;

      // setup chart
      var active = 0;
      var urgent = 0;
      var answered = 0;
      var expired = 0;

      resultData.forEach(res => {
        switch(res.status) {
          case "Active":
            active++;
            break;
          case "Urgent":
            urgent++;
            break;
          case "Answered":
            answered++;
            break;
          case "Expired":
            expired++;
            break;
          default:
            break;
        }
      });
      var total = active + urgent + answered + expired;

      this.setState({ wholeList: resultData, requestList: this.getFilteredList(resultData), fetching: false, chartCount: [active, urgent, answered, expired], total });
    }
    catch {
      this.setState({ wholeList: [], requestList: [], fetching: false });
    }
  }

  getFilteredList(list) {
    var newList = [];

    // if we have a status filter
    if(this.props.match.params.id.toLowerCase() !== "all") {
      for(var i=0; i<list.length; i++) {
        let itemStatus = list[i].status;
        if(itemStatus.toLowerCase() === this.props.match.params.id.toLowerCase())
          newList.push(list[i]);
      }
    }
    else {
      for(var j=0; j<list.length; j++) {
        let itemStatus = list[j].status;
        if(itemStatus === "Active" || itemStatus === "Urgent")
          newList.push(list[j]);
      }
    }
    return newList;
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value, changesExist: true, successMsg: '', errorMsg: '' });
  };

  toggleState = state => {
    this.setState(prevState => ({
      [state]: !prevState[state],
      requestList: prevState[state] ? this.state.wholeList : this.getFilteredList(this.state.wholeList)
    }));
  };

  onClick(context, sub) {
    context.props.history.push("/dashboard/" + sub.toLowerCase());
  }

  searchList(e) {
    const searchQuery = e.target.value;
    this.setState({ searchQuery });
    const removeDashes = str => str.replace(/-/g, '');

    if (searchQuery === '') {
      debounce(() => this.setState({requestList: this.getFilteredList(this.state.wholeList)}), 500)();
    } else {
      debounce(() => {
        this.setState({
          requestList: this.getFilteredList(this.state.wholeList).filter(item => {
            const noDashesSearchQuery = removeDashes(searchQuery);
            var splitStr = noDashesSearchQuery.split(' ');
            var include = true;
            for (var i=0; i<splitStr.length; i++) {
              if (!((item.title && item.title.toLowerCase().includes(splitStr[i].toLowerCase())) ||
                (item.description && item.description.toLowerCase().includes(splitStr[i].toLowerCase())) ||
                (item.requestor && item.requestor.toLowerCase().includes(splitStr[i].toLowerCase())))) {
                include = false;
                break;
              }
            }
            if (include) { return item; }
            else { return null; }
          }),
        });
      }, 500)();
    }
  }

  setSelected(selectedReq) {
    const { requestList } = this.state;
    var pr = selectedReq;
    var index = 0;
    for(var i=0; i<requestList.length; i++) {
      if(requestList[i] === selectedReq) {
        index = i+1;
        break;
      }
    }
    pr.position = index + " of " + requestList.length;
    this.setState({showList: true, selectedPR: pr, selectedIndex: index-1});
  }

  selectNext() {
    const { requestList, selectedIndex } = this.state;
    var index = (selectedIndex < requestList.length - 1) ? selectedIndex+1 : 0;
    var pr = requestList[index];
    pr.position = (index+1) + " of " + requestList.length;
    this.setState({showList: true, selectedPR: pr, selectedIndex: index});
  }

  selectPrev() {
    const { requestList, selectedIndex } = this.state;
    var index = (selectedIndex > 0) ? requestList.length-1 : selectedIndex-1;
    var pr = requestList[index];
    pr.position = (index+1) + " of " + requestList.length;
    this.setState({showList: true, selectedPR: pr, selectedIndex: index});
  }

  editPR() {
    this.props.history.push("/edit-request/" + this.state.selectedPR.requestID);
  }

  /*
  / this function will render the primary display for the pricing
  / engine page. 
  */
  render() {
    const {
      viewAll,
      fetching,
      requestList,
      total,
      chartCount,
      showList,
      selectedPR
    } = this.state;

    const CanvasJSChart = CanvasJSReact.CanvasJSChart;

    const donutOptions = {
      animationEnabled: true,
      subtitles: [{
        text: `${total} Prayer Reqs`,
        verticalAlign: "center",
        fontSize: 20,
        fontFamily: 'Source Sans Pro',
        dockInsidePlotArea: true
      }],
      legend: {
        horizontalAlign: "bottom",
        verticalAlign: "bottom",
        fontFamily: 'Source Sans Pro',
        fontSize: 18,
        fontWeight: 'lighter',
        maxWidth: 200,
      },
      backgroundColor: 'transparent',
      data: [{
        type: "doughnut",
        innerRadius: "60%",
        startAngle: 180,
        dataPoints: [
          { y: chartCount[0], name: "Active", color: 'hsl(193,77%,56%)', click: () => this.onClick(this, "Active") },
          { y: chartCount[1], name: "Urgent", color: 'hsl(51,76%,59%)', click: () => this.onClick(this, "Urgent") },
          { y: chartCount[2], name: "Answered", color: 'hsl(100,66%,45%)', click: () => this.onClick(this, "Answered") },
          { y: chartCount[3], name: "Expired", color: 'hsl(358,79%,47%)', click: () => this.onClick(this, "Expired") }
        ],
        showInLegend: "true",
        toolTip: "{name} - {y}",
      }]
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4">DASHBOARD {this.props.match.params.id && (<small>({this.props.match.params.id.toUpperCase()})</small>)}</h1>
            <h2 className="h6 border-bottom-2 border-gray mb-4">{LoginState.organization}</h2>
          </div>

          {(this.props.match.params.id.toLowerCase() === "all") && (<>
            <div style={{minWidth: '350px', maxWidth: '350px'}}>
              { total ? <CanvasJSChart options={donutOptions} />
              : <p className="placeholder-glow d-flex flex-column justify-content-center align-items-center">
                  <span className="placeholder bg-danger" style={{ width: '273px', height: '273px', borderRadius: '273px'}}></span>
                  <span className="placeholder bg-danger mt-4" style={{ width: '150px', height: '20px'}}></span>
                  <span className="placeholder bg-danger mt-2" style={{ width: '150px', height: '20px'}}></span>
                  <span className="placeholder bg-danger mt-2" style={{ width: '150px', height: '20px'}}></span>
                </p>
              }
            </div>
            <div className="col-12 mb-2 pb-5 col-md-12" />
          </>)}

          <div className="col-12 col-md-4 mt-4">
            <input
              className="form-control"
              onChange={e => this.searchList(e)}
              value={this.state.searchQuery}
              placeholder="Search / Filter Results"
            />
          </div>

          {(this.props.match.params.id.toLowerCase() === "all") && (
            <div className="col-12 col-md-4 pt-2 pt-md-1 mt-4">
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" id="detailViewSwitch" checked={viewAll} onChange={() => this.toggleState('viewAll')} />
                <label className="form-check-label" htmlFor="detailViewSwitch">Show Active Only</label>
              </div>
            </div>
          )}

          {(requestList.length === 0 && !fetching) && (
            <div className="col-12 mt-4">
              <img src="/images/confused.png" alt="" height="100px" width="100px" />
              No Results Found
            </div>
          )}
          {(requestList.length > 0) && (
            <div className="col-12 my-4">
              <div className="table-responsive border rounded">
                <table className="table bg-white mb-0">
                  <thead>
                    <tr>
                      <th>ID #</th>
                      <th>Title</th>
                      <th className='text-center'>Request By</th>
                      <th className='text-center'>Request Date</th>
                      <th className='text-center'>Status</th>
                      <th className='text-center'>Exp Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requestList.map(pr => (
                      <tr key={pr.requestID}>
                        <td>
                          <Link onClick={() => this.setSelected(pr)}>{pr.requestID}</Link>
                        </td>
                        <td style={{maxWidth:'350px'}}>{pr.title}</td>
                        <td className='text-center'>{pr.requestor}</td>
                        <td className='text-center'>{pr.requestDate}</td>
                        <td className='text-center'>
                          <span className={`fw-normal badge rounded-pill pill-${STATUS_COLORS[pr.status]}`}>
                            {pr.status}
                          </span>
                        </td>
                        <td className='text-center'>{pr.expiration}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <PrayerList show={showList} selected={selectedPR} 
          onClose={() => this.setState({showList: false})} 
          onPrev={() => this.selectPrev()} 
          onNext={() => this.selectNext()} 
          onEdit={() => this.editPR()} />
      </div>
    );
  }
}