import { ACTIONS, Session } from '../../helpers/sessionContext';
import { JWT_SECRET_KEY,  ErrorMessage, SuccessMessage, DownloadLink, LoginContainer } from '../../helpers/LayoutComponents';
import { Field, Form } from 'react-final-form';
import React, { useContext, useState } from 'react';

import { Base64 } from 'js-base64';
import LoginState from '../../components/LoginState';
import axios from 'axios';
import jwt from 'jsonwebtoken';

const Login = ({ history }) => {
  const { dispatch } = useContext(Session);
  const [loginView, setLoginView] = useState('login');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const emailValidate = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleLogin = async values => {
    localStorage.setItem('lastTimeStamp', Date.now());

    // if username or password blank, don't process
    if(!values.email) {
      setErrorMessage('Email Not Provided!');
      return;
    }
    else if(!emailValidate.test(String(values.email).toLowerCase())) {
      setErrorMessage('Invalid Email Format!');
      return;
    }
    else if(!values.password) {
      setErrorMessage('Password Not Provided!');
      return;
    }

    try {
      const response = axios.get('Login', {
        params: {
          email: values.email,
          password: Base64.encode(values.password),
        },
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;

      if (resultData) {
        // check if registered
        if(!resultData.displayName) {
          setErrorMessage('Please Finish Registration Through Your Email Before Attempting Login!')
          return;
        }

        LoginState.loggedIn = true;
        LoginState.displayName = resultData.displayName;
        LoginState.userId = resultData.userID;
        LoginState.organization = resultData.organization;

        const token = jwt.sign({ LoginState }, JWT_SECRET_KEY, { algorithm: 'HS256', expiresIn: "6h" });
        dispatch({ type: ACTIONS.SET_USER, value: LoginState });
        localStorage.setItem('user', JSON.stringify(LoginState));
        localStorage.setItem('token', token);
        history.push('/dashboard');
      }
      else { setErrorMessage('Invalid Email and/or Password Specified!'); }
    } catch { setErrorMessage('An Error Occurred While Attempting To Login!'); }
  };

  const sendPasswordEmail = async values => {
    // first validate to make sure the email is valid
    if(!values.email) {
      setErrorMessage('Email Not Provided!');
      return;
    }
    else if(!emailValidate.test(String(values.email).toLowerCase())) {
      setErrorMessage('Invalid Email Format!');
      return;
    }

    try {
      setProcessing(true);
      const response = axios.get('Password', {
        params: { email: values.email }
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;
      setProcessing(false);
      if (resultData !== "Success") {
        setErrorMessage(resultData);
      }
      else {
        setErrorMessage("");
        setSuccessMsg("Password Sent To Email!");
        setLoginView('login');
      }
    }
    catch { setErrorMessage("Error!"); setProcessing(false); }
  };

  const registerUser = async values => {
    // if username or password blank, don't process
    if(!values.email) {
      setErrorMessage('Email Not Provided!');
      return;
    }
    else if(!emailValidate.test(String(values.email).toLowerCase())) {
      setErrorMessage('Invalid Email Format!');
      return;
    }
    else if(!values.firstName) {
      setErrorMessage('First Name Not Provided!');
      return;
    }
    else if(!values.lastName) {
      setErrorMessage('Last Name Not Provided!');
      return;
    }
    else if(!values.orgCode) {
      setErrorMessage('Organization Not Provided!');
      return;
    }
    else if(values.orgCode.length < 5) {
      setErrorMessage('Invalid Organization Code!');
      return;
    }
    else if(!values.password) {
      setErrorMessage('Password Not Provided!');
      return;
    }
    else if(values.password.length < 8) {
      setErrorMessage('Password Too Short!');
      return;
    }
    else if(values.password.length > 15) {
      setErrorMessage('Password Too Long!');
      return;
    }
    else if(!values.confirm) {
      setErrorMessage('Password Not Confirmed!');
      return;
    }
    else if(values.password !== values.confirm) {
      setErrorMessage('Passwords Do Not Match!');
      return;
    }

    try {
      setProcessing(true);
      const response = axios.get('CreateNewUser', {
        params: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          orgCode: values.orgCode,
          password: Base64.encode(values.password),
        },
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;
      setProcessing(false);
      if (resultData !== "Success") {
        setErrorMessage(resultData);
      }
      else {
        setErrorMessage("");
        setSuccessMsg("User Successfully Created!\nCheck Email to Complete Registration");
        setLoginView('login');
      }
    }
    catch { setErrorMessage("Error!"); setProcessing(false); }
  };

  return (
    <LoginContainer>
      {loginView === 'login' && (
        <Form
          onSubmit={handleLogin}
          initialValues={{ email: '', password: '' }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <h4 color="white">SIGN IN</h4>
              <Field autoFocus
                name="email"
                component="input"
                type="text"
                placeholder="Email"
              />
              <Field
                name="password"
                component="input"
                type="password"
                placeholder="Password"
              />
              <button className="btn btn-primary text-white my-2" id="LoginBtn" type="submit">
                {processing ? "Please Wait..." : "Sign In"}
              </button>

              <DownloadLink onClick={() => { setLoginView('forgotpw'); setErrorMessage(''); setSuccessMsg(''); }}>Forgot Password</DownloadLink>
              <DownloadLink onClick={() => { setLoginView('register'); setErrorMessage(''); setSuccessMsg(''); }}>Register New User</DownloadLink>

              {errorMessage && (<ErrorMessage style={{color: 'white'}} id="errMsgSpan" className='mt-2'>{errorMessage}</ErrorMessage>)}
              {successMsg && (<SuccessMessage id="sucMsgSpan" className='mt-2'>{successMsg}</SuccessMessage>)}
            </form>
          )}
        />
      )}

      {loginView === 'forgotpw' && (
        <Form
          onSubmit={sendPasswordEmail}
          initialValues={{ email: '' }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <h4 color="white">FORGOT PASSWORD</h4>
              <p color="gray700">
                Please enter your email address <br/>
                and we'll send your password there.
              </p>
              <Field id="emailTxt" name="email" component="input" type="email" placeholder="Email Address" />
              <button className="btn btn-primary text-white" id="SendBtn" 
                onClick={handleSubmit} disabled={processing}>
                {processing ? "Please Wait..." : "Send"}
              </button>
              <button className="btn btn-light my-2" id="CancelBtn" 
                onClick={() => { setLoginView('login'); setErrorMessage(''); setSuccessMsg(''); }}
                disabled={processing}>
                Cancel
              </button>
              <ErrorMessage style={{color: 'white'}} id="errMsgSpan">{errorMessage}</ErrorMessage>
            </form>
          )}
        />
      )}

      {loginView === 'register' && (
        <Form
          onSubmit={registerUser}
          initialValues={{ email: '', firstName: '', lastName: '', orgCode: 'JRC-SLG01', password: '', confirm: '' }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <h4 color="white">REGISTER USER</h4>
              <Field 
                style={{height: '35px', marginTop: '5px', marginBottom: '5px'}}
                name="email" 
                component="input" 
                type="email" 
                placeholder="Email Address" 
              />
              <Field 
                style={{height: '35px', marginTop: '5px', marginBottom: '5px'}}
                name="firstName" 
                component="input" 
                type="text" 
                placeholder="First Name" 
              />
              <Field 
                style={{height: '35px', marginTop: '5px', marginBottom: '5px'}}
                name="lastName" 
                component="input" 
                type="text" 
                placeholder="Last Name" 
              />
              <Field 
                style={{height: '35px', marginTop: '5px', marginBottom: '5px'}}
                name="orgCode" 
                component="input" 
                type="text" 
                placeholder="Organization Code" 
              />
              <Field
                style={{height: '35px', marginTop: '5px', marginBottom: '5px'}}
                name="password"
                component="input"
                type="password"
                placeholder="Password (8-15 Characters)"
              />
              <Field
                style={{height: '35px', marginTop: '5px', marginBottom: '15px'}}
                name="confirm"
                component="input"
                type="password"
                placeholder="Confirm Password"
              />
              <button className="btn btn-primary text-white" id="SendBtn" type="submit"
                onClick={handleSubmit} disabled={processing}>
                {processing ? "Please Wait..." : "Register"}
              </button>
              <button className="btn btn-light my-2" id="CancelBtn" 
                onClick={() => { setLoginView('login'); setErrorMessage(''); setSuccessMsg(''); }} 
                disabled={processing}>
                Cancel
              </button>
              <ErrorMessage style={{color: 'white'}} id="errMsgSpan">{errorMessage}</ErrorMessage>
            </form>
          )}
        />
      )}
    </LoginContainer>
  );
};

export default Login;
