import React, { Component } from 'react';
import { Input } from './UI/Inputs';
import axios from 'axios';

export default class Request extends Component {

  constructor(props) {
    super(props);
    this.state = {
      requestor: '',
      title: '',
      description: '',
      successMsg: '',
      errorMsg: ''
    };

    this.GetPrayerRequest();
  }

  async GetPrayerRequest() {
    try {
      const response = axios.get('/FetchRequest', {
        params: { requestID: this.props.match.params.id }
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;
      this.setState({ 
        requestor: resultData.requestor, 
        title: resultData.title, 
        description: resultData.description, 
        expiration: resultData.expiration,
        fetching: false 
      });
    }
    catch {
      this.setState({ 
        requestor: '', 
        title: '', 
        description: '', 
        expiration: '',
        fetching: false 
      });
    }
  }

  render() {
    const {
      errors,
      requestor,
      title,
      description,
      expiration,
      urgentCB,
      successMsg,
      errorMsg
    } = this.state;
    console.log(expiration);
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <h1 className="h4">PRAYER REQUEST</h1>
          </div>
          
          <div className="col-12 col-md-6">
            <div className='mb-4'>
              <Input
                id="requestor"
                name="requestor"
                label="Requested By"
                type="text"
                maxLength={300}
                value={requestor}
                disabled={true}
                tabIndex={1}
              />
            </div>

            <div className='mb-4'>
              <Input
                id="title"
                name="title"
                label="Request Title"
                type="text"
                maxLength={300}
                value={title}
                disabled={true}
                tabIndex={2}
              />
            </div>

            <div className='mb-4'>
              <label className='form-label mb-0'>Description</label>
              <textarea style={{resize: 'none'}}
                rows="5"
                className="form-control" 
                id="description"
                name="description"
                maxLength={3000}
                value={description}
                disabled={true}
                tabIndex={3}
              />
            </div>

            <div className='mb-4'>
              {expiration ? (
                <Input
                  id="expiration"
                  name="expiration"
                  label="Expiration"
                  type="date"
                  value={expiration}
                  disabled={true}
                  tabIndex={4}
                />
              ) : (
                <Input 
                  label="Expiration"
                  type="text" 
                  value="No Expiration Date"
                  disabled={true}
                  tabIndex={4}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
