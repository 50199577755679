import { BrowserRouter, Route } from 'react-router-dom';
import { GlobalStyle, Theme } from './theme';
import React, { useContext, useEffect } from 'react';
import AuthedRoutes from './routes/authed';
import GlobalNavBar from './components/GlobalNavBar';
import PublicRoutes from './routes/public';
import { ACTIONS, Session } from './helpers/sessionContext';
import { JWT_SECRET_KEY } from './helpers/LayoutComponents';
import { ThemeProvider } from 'styled-components';
import jwt from 'jsonwebtoken';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  const { state, dispatch } = useContext(Session);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        jwt.verify(token, JWT_SECRET_KEY);
      } catch (error) {
        dispatch({ type: ACTIONS.REMOVE_USER });
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('lastTimeStamp');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <BrowserRouter>
        <ScrollToTop />
        <GlobalNavBar user={state.user} />
        {state.user ? (
          <Route component={() => <AuthedRoutes user={state.user} />} />
        ) : (
          <Route component={PublicRoutes} />
        )}
      </BrowserRouter>
    </ThemeProvider>
  );
};
export default App;
