import React, { Component } from 'react';
import axios from 'axios';

export default class Registration extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      displayName: '',
      email: ''
    };
    this.RegisterUser();
  }

  async RegisterUser() {
    try {
      console.log(this.props.match.params.id);
      const response = axios.get('/RegisterUser', {
        params: { registrationKey: this.props.match.params.id }
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;
      this.setState({ displayName: resultData.userName, email: resultData.userEmail, fetching: false });
    }
    catch {
      this.setState({ displayName: '', email: '', fetching: false });
    }
  }

  render() {
    const {
      fetching,
      displayName,
      email
    } = this.state;
    return (
      <div className="container" style={{marginTop: '50px'}}>
        <div className="row">
          <div className="col-12 mb-4 mt-4">
            <h1 className="h4">USER REGISTRATION</h1>
          </div>

          {fetching && (
            <div className="col-12">
              <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
            </div>
          )}

          {displayName && (
            <div className="col-12 col-md-12">
              User Registration Complete For: 
              <br/>
              <b>{displayName}</b>
              <br/>
              <br/>
              You May Now Login Using This Email: 
              <br/>
              <b>{email}</b>
            </div>
          )}

          {(!fetching && !displayName) && (
            <div className="col-12 col-md-12">
            Invalid Registration Key Provided,
            <br/>
            Or User Has Already Been Registered Using This Key
          </div>
          )}

          <button className="btn btn-primary text-white" style={{maxWidth: '150px', marginLeft: '10px', marginTop: '30px'}} id="LoginBtn" onClick={() => this.props.history.push("/")}>
            Log In
          </button>
        </div>
      </div>
    )
  }
}
