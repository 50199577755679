import { Redirect, Route, Switch } from 'react-router-dom';
import Landing from '../containers/Landing/index';
import TimedOut from '../containers/TimedOut';
import Registration from '../components/Registration';
import React from 'react';

const PublicRoutes = () => {

  // when not logged in, this will save the last page attempted to visit into
  // into a stored location. then we can redirect there after login.
  var saveLoc = window.location.href.substring(8);
  saveLoc = saveLoc.substring(saveLoc.indexOf('/'));
  if(saveLoc.length > 1)
    localStorage.setItem('lastPage', saveLoc);

  return (
    <Switch>
      <Route path="/timedout" component={TimedOut} />
      <Route exact path="/" component={Landing} />
      <Route path="/registration/:id" component={Registration} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default PublicRoutes;
