import React, { Component } from 'react';

export default class Success extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <h1 className="h4">PRAYER REQUEST</h1>
          </div>
          
          <div className="col-12 col-md-6">
            <div>Your Prayer Request Was Successfully {this.props.match.params.id === "sub" ? "Submitted" : "Updated"}!</div>
            <button className="btn btn-primary text-white mt-4"
              onClick={() => this.props.history.push("/all")}>
              To Dashboard
            </button>
          </div>
        </div>
      </div>
    )
  }
}
