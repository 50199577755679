import React, { Component } from 'react';
import { Link } from '../components/LayoutComponents';
import axios from 'axios';
import LoginState from './LoginState';
import { STATUS_COLORS } from '../helpers/utils';
import { debounce } from 'lodash';
import PrayerList from '../components/PrayerList';

export default class MyRequests extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      fetching: true,
      viewAll: true,
      wholeList: [],
      requestList: [],
      showList: false,
      selectedPR: [],
      selectedIndex: 0
    };

    this.FetchMyRequests();
  }

  async FetchMyRequests() {
    try {
      const response = axios.get('/FetchMyRequests', {
        params: { userEmail: LoginState.userId }
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;
      this.setState({ wholeList: resultData, requestList: this.getFilteredList(resultData), fetching: false });
    }
    catch {
      this.setState({ wholeList: [], requestList: [], fetching: false });
    }
  }

  getFilteredList(list) {
    var newList = [];
    for(var i=0; i<list.length; i++) {
      let itemStatus = list[i].status;
      if(itemStatus === "Active" || itemStatus === "Urgent")
        newList.push(list[i]);
    }
    return newList;
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value, changesExist: true, successMsg: '', errorMsg: '' });
  };

  toggleState = state => {
    this.setState(prevState => ({
      [state]: !prevState[state],
      requestList: prevState[state] ? this.state.wholeList : this.getFilteredList(this.state.wholeList)
    }));
  };

  searchList(e) {
    const searchQuery = e.target.value;
    this.setState({ searchQuery });
    const removeDashes = str => str.replace(/-/g, '');

    if (searchQuery === '') {
      debounce(() => this.setState({requestList: this.getFilteredList(this.state.wholeList)}), 500)();
    } else {
      debounce(() => {
        this.setState({
          requestList: this.getFilteredList(this.state.wholeList).filter(item => {
            const noDashesSearchQuery = removeDashes(searchQuery);
            var splitStr = noDashesSearchQuery.split(' ');
            var include = true;
            for (var i=0; i<splitStr.length; i++) {
              if (!((item.title && item.title.toLowerCase().includes(splitStr[i].toLowerCase())) ||
                (item.description && item.description.toLowerCase().includes(splitStr[i].toLowerCase())) ||
                (item.requestor && item.requestor.toLowerCase().includes(splitStr[i].toLowerCase())))) {
                include = false;
                break;
              }
            }
            if (include) { return item; }
            else { return null; }
          }),
        });
      }, 500)();
    }
  }

  setSelected(selectedReq) {
    const { requestList } = this.state;
    var pr = selectedReq;
    var index = 0;
    for(var i=0; i<requestList.length; i++) {
      if(requestList[i] === selectedReq) {
        index = i+1;
        break;
      }
    }
    pr.position = index + " of " + requestList.length;
    this.setState({showList: true, selectedPR: pr, selectedIndex: index-1});
  }

  selectNext() {
    const { requestList, selectedIndex } = this.state;
    var index = (selectedIndex < requestList.length - 1) ? selectedIndex+1 : 0;
    var pr = requestList[index];
    pr.position = (index+1) + " of " + requestList.length;
    this.setState({showList: true, selectedPR: pr, selectedIndex: index});
  }

  selectPrev() {
    const { requestList, selectedIndex } = this.state;
    var index = (selectedIndex > 0) ? requestList.length-1 : selectedIndex-1;
    var pr = requestList[index];
    pr.position = (index+1) + " of " + requestList.length;
    this.setState({showList: true, selectedPR: pr, selectedIndex: index});
  }

  editPR() {
    this.props.history.push("/edit-request/" + this.state.selectedPR.requestID);
  }

  render() {
    const {
      viewAll,
      fetching,
      requestList,
      showList,
      selectedPR
    } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <h1 className="h4">MY PRAYER REQUESTS</h1>
          </div>

          <div className="col-12 col-md-4">
            <input
              className="form-control"
              onChange={e => this.searchList(e)}
              value={this.state.searchQuery}
              placeholder="Search / Filter Results"
            />
          </div>

          <div className="col-12 col-md-4 pt-2 pt-md-1">
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" role="switch" id="detailViewSwitch" checked={viewAll} onChange={() => this.toggleState('viewAll')} />
              <label className="form-check-label" htmlFor="detailViewSwitch">Show Active Only</label>
            </div>
          </div>

          {(requestList.length === 0 && !fetching) && (
            <div className="col-12 mt-4">
              <img src="/images/confused.png" alt="" height="100px" width="100px" />
              No Results Found
            </div>
          )}
          {(requestList.length > 0) && (
            <div className="col-12 my-4">
              <div className="table-responsive border rounded">
                <table className="table bg-white mb-0">
                  <thead>
                    <tr>
                      <th>ID #</th>
                      <th>Title</th>
                      <th className='text-center'>Request Date</th>
                      <th className='text-center'>Status</th>
                      <th className='text-center'>Exp Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requestList.map(pr => (
                      <tr key={pr.requestID}>
                        <td>
                          <Link onClick={() => this.setSelected(pr)}>{pr.requestID}</Link>
                        </td>
                        <td>{pr.title}</td>
                        <td className='text-center'>{pr.requestDate}</td>
                        <td className='text-center'>
                          <span className={`fw-normal badge rounded-pill pill-${STATUS_COLORS[pr.status]}`}>
                            {pr.status}
                          </span>
                        </td>
                        <td className='text-center'>{pr.expiration}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <PrayerList show={showList} selected={selectedPR} 
          onClose={() => this.setState({showList: false})} 
          onPrev={() => this.selectPrev()} 
          onNext={() => this.selectNext()}
          onEdit={() => this.editPR()} />
      </div>
    )
  }
}
