import styled from 'styled-components';

export const TitleBar = styled.div`
  margin: 0;
  width: 100%;
  height: 65px;
  background-color: white;
  border-bottom: 1px solid gray;
`;

export const PageTitle = styled.span`
  font-family: 'Permanent Marker';
  font-size: 40px;
  cursor: pointer;
`;

export const CenterFixed = styled.div`
  min-width: 800px;
  max-width: 1600px;
  height: 64px;
  margin: 0 auto;
  text-align: center;
`;

export const CenterContent = styled.div`
  min-width: 800px;
  max-width: 1600px;
  max-width: 1600px;
  height: calc(100vh - 65px);
  margin: 0 auto;
  background-color: #f7f7ff;
`;

export const ContentPane = styled.div`
  position: fixed;
  left: 0px;
  top: 65px;
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  overflow-x: hidden;
  transition: width 0.2s;
  border-top: 1px solid lightgray;
`;

export const DisplaySpan = styled.span`
  font-family: 'PT Sans';
  font-size: '13px';
`;

export const PageWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  padding-top: 30px;
  font-size: 14px;
  font-family: 'Verdana';
`;

export const CenterDiv = styled.div`
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
`;

export const TabControl = styled.div`
  width: ${p => p.width ? p.width : '800px'};
  margin: 0 auto;
`;

export const TabHeaders = styled.div`
  height: 35px;
  padding: 0px;
  margin: 0px;
  display: flex; 
  border-bottom: 1px solid white;
`;

export const Tab = styled.div`
  font-family: 'Rancho';
  font-weight: 14px;
  height: 35px;
  width: 150px;
  text-align: center;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin: 0px;
  background-color: ${p => p.active ? 'white' : '#e6e8f0'};
  border-top: 2px solid ${p => p.active ? 'green' : 'transparent'};
  border-left: 1px solid ${p => p.active ? 'lightgray' : 'transparent'}; 
  &:hover { 
    border-top: 2px solid ${p => p.active ? 'green' : '#305ba1' }; 
    background-color: ${p => p.active ? 'white' : '#d7ddf5' };
  }
  border-bottom: 1px solid white;
  font-size: 23px;
`;

export const TabContent = styled.div`
  height: ${p => p.height ? p.height : '500px'};
  background-color: white;  
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  box-shadow: 3px 3px 3px #a7b3c7;
  padding: 15px;
`;

export const TextBox = styled.input`
  width: ${p => p.width ? p.width : '200px'};
  background-color: white;
  border: 1px solid lightgray;
  box-shadow: 2px 2px 2px #a7b3c7;
  &:focus {
    border: 1px solid #37426e;
    box-shadow: 2px 2px 2px #4d598a;
    outline: none;
  }
  margin: 2px 0px 2px 0px;
  
  &::placeholder {
    padding-left: 2px;
    font-size: 10px;
  }
`;

export const TextArea = styled.textarea`
  width: ${p => p.width ? p.width : '200px'};
  height: ${p => p.height ? p.height : '50px'};
  background-color: white;
  resize: none;
  line-height: 1;
  border: 1px solid lightgray;
  box-shadow: 2px 2px 2px #a7b3c7;
  &:focus {
    border: 1px solid #37426e;
    box-shadow: 2px 2px 2px #4d598a;
    outline: none;
  }
`;

export const Button = styled.button`
  width: ${p => p.width ? p.width : '130px'};
  height: ${p => p.height ? p.height : '28px'};
  background-color: green;
  border: 2px solid #37426e;
  border-radius: 3px;
  color: white;
  padding-bottom: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Verdana;
  font-size: 12px;
  &:hover {
    background-color: #4caf66;
  }
  &:active {
    background-color: #6ccf86;
    border: 2px solid #37426e;
    outline: none;
  }
  &:focus {
    border: 2px solid #37426e;
    outline: none;
    box-shadow: 2px 2px 2px #4d598a;
  }
  &:disabled {
    background-color: #6ccf86;
  }
`;

export const UserImage = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 18px;
  box-shadow: inset 0 2px 4px hsla(0, 0%, 0%, 0.2);
`

export const UserDisplay = styled.span`
  font-family: Verdana;
  font-size: 12px;
`;

export const Link = styled.span`
  color: red;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: pink;
  }
  &:active {
    color: yellow;
  }
`;

export const BlackLink = styled.span`
  font-family: Verdana;
  font-size: 12px;
  color: black;
  cursor: pointer;
  &:hover {
    color: #37426e;
    text-decoration: underline;
  }
  &:active {
    color: blue;
  }
`;

export const YellowLink = styled.span`
  font-family: Verdana;
  font-size: 12px;
  color: yellow;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #7d8fad;
  }
  &:active {
    color: white;
  }
`;

export const NodeBorder = styled.div`
  width: 90%;
  border: 1px solid ${p => p.color ? p.color : 'black'};
  margin: 10px;
  box-shadow: 2px 2px 2px ${p => p.color ? p.color : '#4d598a'};
  background-color: white;
`;

export const NodeLinkTD = styled.td`
  vertical-align: top;
  width: 49%;
`;

export const NodeSpacerTD = styled.td`
  width: 2%;
`;

export const NodeLinkDiv = styled.div`
  border: 1px solid ${p => p.color ? p.color : 'black'};
  box-shadow: 2px 2px 2px ${p => p.color ? p.color : '#4d598a'};
  background-color: white;
`;

export const LinksTable = styled.table`
  width: 90%;
  margin: 10px;
`;

export const BlueHeader = styled.div`
  position: relative;
  color: white;
  height: 30px;
  background-color: ${p => p.color ? p.color : '#37426e'};
  width: 100%;
  padding: 5px;
  text-overflow: clip;
  white-space: nowrap;
`;

export const FaintText = styled.span`
  font-size: 10px;
  color: ${p => p.color ? p.color : '#909399'};
`;

export const NodeTextArea = styled.div`
  min-height: 50px;
  width: 100%;
  padding: 5px;
  white-space: pre-wrap;
`;

export const NodeLimitWrap = styled.span`
  max-width: 390px;
  display:inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TextFieldBorder = styled.div`
  width: 90%;
  border: 1px solid black;
  margin: 10px;
  box-shadow: 2px 2px 2px #4d598a;
  background-color: white;
  padding: 5px;
`;

export const ClearTextBox = styled.textarea`
  height: ${p => p.height ? p.height : '50px'};
  width: 100%;
  resize: none;
  line-height: 1;
  background-color: white;
  padding: 3px;
  border: none;
  outline: none;
  &:focus {
    border-top: 1px solid #e2e2e2;
  }
`;

export const SpacerDiv = styled.div`
  height: ${p => p.height ? p.height : '15px'};
`;

export const BubbleSpan = styled.span`
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 3px;
`;

export const HeaderDiv = styled.div`
  min-width: 800px;
  max-width: 1600px;
  width: 100%;
  height: 35px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 0px; 
  background-color: #627180;
  color: white;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
`;

export const HeaderTable = styled.table`
  width: 100%;
  height: 30px;
  margin: 0px;
  padding: 0px;
`;

export const UserSelectOffTable = styled.table`
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

export const LeftTD = styled.td`
  text-align: left;
`;

export const RightTD = styled.td`
  text-align: right;
`;

export const CenterTD = styled.td`
  text-align: center;
`;

export const FixedRightTD = styled.td`
  text-align: right;
  padding-right: 10px;
  width: 230px;
  min-width: 230px;
`;

export const NodeTree = styled.select`
  width: 300px;
  border: 0px;
  border-bottom: 2px solid #7d8fad;
  margin: 0px 0px 0px 0px;
  background-color: #627180;
  color: white;
  font-family: Verdana;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid #8d9fbd;
  }

  & option {
    background-color: white;
    color: black;
  }
`;

export const SmallText = styled.span`
  font-family: Verdana;
  font-size: 12px;
`;

export const PreviewFill = styled.div`
  margin: 0;
  flex-grow: 1;
  padding: 5px;
  height: 450px;
`;

export const ButtonTD = styled.td`
  text-align: center;
  color: #7d8fad;
  border: 2px solid #7d8fad;
  cursor: pointer;

  &:hover {
    border: 2px solid #8d9fbd;
    color: aliceblue;
  }
`;