import React from 'react';
import styled, { css } from 'styled-components';
import { formatCurrency } from '../../helpers/utils';

/* -------------------------------- 
Inputs
-------------------------------- */

const inputMixin = css`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 42px;
  font-size: 20px;
  margin-left: -2px;
  font-weight: ${p => (p.value ? '500' : '300')};
  outline: none;
  border-radius: ${p => p.theme.border.radius};
  border: none;
  border-bottom: 1px solid
    ${p =>
      p.valid
        ? p.theme.colors.success300
        : p.invalid
        ? p.theme.colors.danger300
        : p.theme.colors.gray300};
  background-color: ${p =>
    p.valid
      ? p.theme.colors.success100
      : p.invalid
      ? p.theme.colors.danger100
      : 'transparent'};
  color: ${p => (p.value ? p.theme.colors.gray700 : p.theme.colors.gray300)};
  &:hover {
    border-bottom: 1px solid
      ${p =>
        p.valid
          ? p.theme.colors.success700
          : p.invalid
          ? p.theme.colors.red700
          : p.theme.colors.primary500};
  }
  &:disabled {
    border-bottom: 1px dotted ${p => p.theme.colors.gray100};
  }
`;

const FormGroup = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: left;
  padding-top: ${p => p.theme.spacing.sm};
  padding-bottom: 8px;
  span {
    font-size: 14px;
    color: ${p => p.theme.colors.danger500};
    position: absolute;
    top: 40px;
  }
  &:focus-within {
    label {
      font-size: 13px;
      top: 8px;
    }
  }
`;

const StyledInput = styled.input`
  ${inputMixin}
  &::placeholder {
    color: transparent;
  }
`;

const StyledSelect = styled.select`
  ${inputMixin}
`;

const Label = styled.label`
  color: ${p => (p.value ? p.theme.colors.gray500 : p.theme.colors.gray400)};
  text-transform: capitalize;
  top: ${p => (p.value ? '8px' : '26px')};
  font-size: ${p => (p.value ? '13px' : '1rem')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${p =>
    p.type !== 'date' &&
    `
        position: absolute;
        z-index: 0;
        left: ${p => (p.value ? '0px' : '20px')};
        transition: top 0.1s, left 0.1s;
    `}
`;

const SwitchWrapper = styled.div`
  margin-left: 25px;
  display: flex;
  align-items: center;
  h3 {
    font-weight: 500;
    color: ${p => p.theme.colors.gray500};
    text-transform: capitalize;
    margin: 0;
    margin-right: 8px;
  }
`;

const StyledSwitch = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
  width: 40px;
  height: 20px;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  &:checked {
    background: ${p => p.theme.colors.primary500};
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 20px;
    top: 3px;
    left: 4px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
  }
  &:checked:before {
    left: 22px;
  }
`;

const Wrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 0px 1rem 1.5rem 0;
  box-sizing: border-box;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  box-sizing: border-box;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
`;
const RadioButton = styled.input`
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export const InputPlaceholder = styled.div`
  width: 100%;
  height: 66px;
`;

export const RadioGroup = ({
  name,
  label,
  options = [],
  error,
  value,
  onChange,
  helperText,
  ...rest
}) => (
  <Wrapper>
    {options.map(option => (
      // <FormControlLabel key={contact.value} value={contact.value} control={<Radio />} label={contact.label} />
      <Item>
        <RadioButton
          type="radio"
          name={name}
          value={option.value}
          // checked={value === option.value}
          onChange={e => onChange(e)}
        />
        <RadioButtonLabel />
        <div>{option.label}</div>
      </Item>
    ))}
    {helperText && <p>{helperText}</p>}
    </Wrapper>
);

export const CocSelect = ({
  name,
  label,
  options = [],
  error,
  placeholder,
  value,
  onChange,
  ...rest
}) => (
  <StyledSelect
    id={name}
    name={name}
    value={value || -1}
    {...rest}
    invalid={error}
    onChange={e => onChange(e)}
    style={{
      width: '100%',
      fontSize: '16px',
      textAlign: 'left',
      height: '24px',
      borderBottom: '1px solid lightgray',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none' 
    }}
  >
    <option key="placeholder" disabled="disabled" value="-1">{placeholder}</option>
    {options.map((option, index) => (
      <option
        key={`${option.label}-${option.value}-${index}`}
        value={option.value}
      >
        {option.label}
      </option>
    ))}
  </StyledSelect>
);

export const HiddenSelect = ({
  name,
  label,
  options = [],
  error,
  value,
  onChange,
  ...rest
}) => (
  <select
    id={name}
    name={name}
    value={value || -1}
    {...rest}
    invalid={error}
    onChange={e => onChange(e)}
    style={{border: 'none', maxHeight:'20px', maxWidth: '18px', margin: '0px', padding: '0px', backgroundColor: 'transparent'}}
  >
    <option key="placeholder" disabled="disabled" value="-1"></option>
    {options.map((option, index) => (
      <option
        key={`${option.label}-${option.value}-${index}`}
        value={option.value}
      >
        {option.label}
      </option>
    ))}
  </select>
);

export const Switch = ({ label, error, ...rest }) => (
  <SwitchWrapper>
    <h3>{label}</h3>
    <StyledSwitch {...rest} />
    {/* {error && <span>{error}</span>} */}
  </SwitchWrapper>
);

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  & + span {
    margin-left: 8px;
    font-weight: ${p => p.theme.weight.bold};
    color: ${p =>
      p.checked ? p.theme.colors.success500 : p.theme.colors.gray500};
  }
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${p => p.theme.colors.success500};
  stroke-width: 3px;
`;

// Hide checkbox visually but remain accessible to screen readers.
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 2px;
  border: 2px solid
    ${p => (p.checked ? p.theme.colors.success500 : p.theme.colors.gray300)};
  ${Icon} {
    visibility: ${p => (p.checked ? 'visible' : 'hidden')};
  }
`;

export const TextSpan = styled.span`
  font-weight: bold;
  color: ${p => p.glow ? p.theme.colors.success500 : p.theme.colors.gray500};
`;

export const Checkbox = ({ checked, label, ...props }) => (
  <label>
    <CheckboxContainer checked={checked}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
    <span>{label}</span>
  </label>
);

export const CurrencyInput = ({
  name,
  label,
  value,
  onChange,
  error,
  ...props
}) => {
  const handleChange = ({ key }) => {
    let selectedText = '';
    if (typeof window.getSelection !== 'undefined') {
      selectedText = window
        .getSelection()
        .toString()
        .replace(/[^0-9]/g, '');
    }
    if (
      (key === 'Backspace' && selectedText) ||
      (key === 'Delete' && selectedText)
    ) {
      const valueStr = value.toString();
      const newValue =
        valueStr === selectedText ? '0' : valueStr.replace(selectedText, '');
      onChange({ target: { name, value: Number(newValue) } });
      return;
    }
    if (key !== 'Backspace' && !/^[0-9]{1}$/.test(key)) return;
    if (!value && key === 'Backspace') return;
    let newValue = '';
    if (key === 'Backspace' || key === 'Delete') {
      if (value) {
        newValue = value.toString().slice(0, -1);
      } else {
        newValue = 0;
      }
    } else {
      if (value) {
        if (selectedText) {
          const valueStr = value.toString();
          newValue = valueStr.replace(selectedText, key);
        } else {
          newValue = value + key;
        }
      } else {
        newValue = key;
      }
    }
    onChange({ target: { name, value: Number(newValue) } });
  };

  return (
    <div className="mb-2">
      <label className="form-label mb-0">{label}</label>
      <input
        className={`form-control ${error && 'is-invalid'}`}
        {...props}
        id={name}
        name={name}
        type="text"
        value={formatCurrency(value, true)}
        onKeyDown={handleChange}
        onChange={() => {}}
      />
      {error && <div className='invalid-feedback'>{error}</div>}
    </div>
  );
};

export const Input = ({ label, error, ...props}) => {
  return (
    <div className="m-0">
      <label className='form-label mb-0'>{label}</label>
      <input 
        className={`form-control ${error && 'is-invalid'}`}
        {...props}
      />
      {error && <div className='invalid-feedback'>{error}</div>}
    </div>
  )
}

export const Select = ({ label, error, options, ...props }) => {
  return (
    <div className="m-0">
      {label && <label className='form-label mb-0'>{label}</label>}
      <select className="form-select"
        {...props}
        disabled={!options}
      >
        <option value="-1">Select...</option>
        {options.map(el => (<option key={el.value} value={el.value}>{el.label}</option>))}
        </select>
      {error && <div className='invalid-feedback'>{error}</div>}
    </div>
  )
}