import { createGlobalStyle, css } from 'styled-components';

/* -------------------------------- 
Theme
-------------------------------- */
const colors = {
  primary100: 'hsl(358,79%,92%)',
  primary300: 'hsl(358,79%,77%)',
  primary500: 'hsl(358,79%,62%)',
  primary700: 'hsl(358,79%,47%)',
  primary900: 'hsl(358,79%,32%)',
  accent500: 'hsl(20,100%,24%)',
  accent700: 'hsl(20,100%,12%)',
  accent900: 'hsl(20,100%,6%)',
  info100: 'hsl(200,79%,97%)',
  info300: 'hsl(200,79%,77%)',
  info500: 'hsl(200,79%,62%)',
  info700: 'hsl(200,79%,47%)',
  info900: 'hsl(200,79%,32%)',
  success100: 'hsl(158,66%,87%)',
  success300: 'hsl(158,66%,77%)',
  success500: 'hsl(158,66%,62%)',
  success700: 'hsl(158,66%,37%)',
  success900: 'hsl(158,66%,22%)',
  danger100: 'hsl(0,79%,62%,.2)',
  danger300: 'hsl(0,79%,77%)',
  danger500: 'hsl(0,79%,62%)',
  danger700: 'hsl(0,79%,47%)',
  danger900: 'hsl(0,79%,32%)',
  warning100: 'hsl(53,79%,97%)',
  warning300: 'hsl(53,79%,77%)',
  warning500: 'hsl(53,79%,62%)',
  warning700: 'hsl(53,79%,47%)',
  warning900: 'hsl(53,79%,32%)',

  /* Gray Scale */
  white: 'hsl(0,0%,100%)',
  offWhite: 'hsl(0,0%,99%)',
  gray100: 'hsl(0,0%,95%)',
  gray200: 'hsl(0,0%,90%)',
  gray300: 'hsl(0,0%,80%)',
  gray400: 'hsl(0,0%,60%)',
  gray500: 'hsl(0,0%,50%)',
  gray600: 'hsl(0,0%,40%)',
  gray700: 'hsl(0,0%,30%)',
  gray800: 'hsl(0,0%,20%)',
  gray900: 'hsl(0,0%,10%)',
  black: 'hsl(0,0%,5%)',
};

const spacing = {
  xs: '0.5rem', 
  sm: '1rem',
  md: '2rem',
  lg: '4rem',
  xl: '6rem'
}

const shadow = {
  xs: '0 1px 3px hsla(0, 0%, 0%, 0.2)', 
  sm: '0 4px 6px hsla(0, 0%, 0%, 0.2)',
  md: '0 5px 15px hsla(0, 0%, 0%, 0.2)',
  lg: '0 10px 24px hsla(0, 0%, 0%, 0.2)',
  xl: '0 15px 35px hsla(0, 0%, 0%, 0.2)'
}

const border = {
  radiusSM: '2px',
  radiusLG: '0.25rem',
}

const weight = {
  light: 300,
  medium: 500,
  bold: 700
}

export const Theme = {
  colors,
  spacing,
  border,
  shadow,
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  weight,
};

export const GlobalStyle = createGlobalStyle`
${() =>
  css`
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700;900&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap');

    html,
    body {
      scroll-behavior: smooth;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      height: 100%;
      font-size: 16px;
    }
    * {
      box-sizing: inherit;
      font-family: 'Source Sans Pro', sans-serif;
    }
    
    /* Hide default input number arrows */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
  `}
`;
