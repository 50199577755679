import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import React, { useContext } from 'react';

import Dashboard from '../containers/Dashboard';
import Forms from '../components/Forms';
import Help from '../containers/Help';
import ProductMatrix from '../components/ProductMatrix';
import Sidebar from '../components/SideBar';
import NewRequest from '../components/NewRequest';
import MyRequests from '../components/MyRequests';
import Registration from '../components/Registration';
import Request from '../components/Request';
import EditRequest from '../components/EditRequest';
import { ACTIONS, Session } from '../helpers/sessionContext';
import { checkRouteForString, useCountdown } from '../helpers/utils';
import Success from '../components/Success';

const AuthedRoutes = ({ user }) => {
  const { dispatch } = useContext(Session);
  const history = useHistory();

  const lastTS = localStorage.getItem('lastTimeStamp');
  const targetDate = lastTS ? (Number(lastTS) + 60 * 60 * 6 * 1000) : 0;
  const [countDownComplete] = useCountdown(targetDate, Number(lastTS));
  if (countDownComplete && lastTS) {
    // logout automatically
    dispatch({ type: ACTIONS.REMOVE_USER });
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('lastTimeStamp');
    history.push('/timedout');
  }

  const updateTimestamp = () => {
    if (!lastTS) {
      // no timestamp found (logout)
      dispatch({ type: ACTIONS.REMOVE_USER });
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('lastTimeStamp');
      history.push('/');
    } else {
      const elapsedSeconds = (Date.now() - lastTS) / 1000;
      if (elapsedSeconds > 60 * 60 * 6) {
        // 6 hours
        // time exceeded (logout)
        dispatch({ type: ACTIONS.REMOVE_USER });
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('lastTimeStamp');
        history.push('/');
      } else {
        // update elapsed time
        localStorage.removeItem('lastTimeStamp');
        localStorage.setItem('lastTimeStamp', Date.now());
      }
    }
  }

  const isRegistration = checkRouteForString('registration', history.location.pathname.toLowerCase());

  if(isRegistration) {
    return (
      <Switch>
        <Route path="/registration/:id" component={Registration} />
      </Switch>
    );
  }
  else {
    return (
      <div className="main" onClick={() => updateTimestamp()}>
        <div className="bg border-end position-fixed d-flex flex-column p-3 sidebar overflow-auto">
          <Sidebar />
        </div>
        <Switch>
          {/* RESOURCES */}
          <Route exact path="/resources" component={NewRequest} />
          <Route
            exact
            path="/resources/product-matrix"
            component={ProductMatrix}
          />
          <Route exact path="/resources/forms" component={Forms} />

          {/* OTHERS */}
          <Route exact path="/help" component={Help} />
          <Route exact path="/dashboard/:id" component={Dashboard} />
          <Route exact path="/new-request" component={NewRequest} />
          <Route exact path="/my-requests" component={MyRequests} />
          <Route path="/request/:id" component={Request} />
          <Route path="/edit-request/:id" component={EditRequest} />
          <Route path="/success/:id" component={Success} />
          <Redirect from="*" to="/dashboard/all" />
        </Switch>
      </div>
    );
  }
};
export default AuthedRoutes;
