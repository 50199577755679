import React, { Component } from 'react'
export default class Help extends Component {
    render() {
        return (
            <div className="container">
                <p>Help</p>
            </div>
        )
    }
}
