import React, { Component } from 'react';
import { Input } from '../components/UI/Inputs';
import axios from 'axios';
import LoginState from './LoginState';
import { ErrorMessage, SuccessMessage } from '../helpers/LayoutComponents';

export default class NewRequest extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      errors: {},
      title: '',
      successMsg: '',
      errorMsg: ''
    };
  }

  async sendRequest() {
    try {
      const response = axios.get('/SubmitPrayerReq', {
        params: {
          title: this.state.title,
          description: this.state.description,
          expDate: this.state.expiration,
          isUrgent: this.state.urgentCB ? "1" : "0",
          userEmail: LoginState.userId,
        },
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;

      if (resultData !== "Success") {
        this.setState({ errorMsg: resultData });
      }
      else {
        /* // todo: maybe redirect to request details page?
        this.setState({ 
          successMsg: 'Prayer Request Submitted!',
          title: '',
          description: '',
          expiration: '',
          noExpCB: false,
          isUrgentCB: false
        }); */
        this.props.history.push("/success/sub");
      }
    }
    catch { this.setState({ errorMsg: 'Error!' }); }
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value, changesExist: true, successMsg: '', errorMsg: '' });
    console.log(this.state.expiration);
  };

  checkBoxClicked(targetName) {
    var checkedTest = this.state[targetName] ? this.state[targetName] : false;
    this.setState({
      [targetName]: !checkedTest,
      expiration: (targetName === "noExpCB" && checkedTest === false) ? '' : this.state.expiration
    });
  }

  render() {
    const {
      errors,
      title,
      description,
      expiration,
      noExpCB,
      urgentCB,
      successMsg,
      errorMsg
    } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <h1 className="h4">PRAYER REQUEST</h1>
          </div>
          
          <div className="col-12 col-md-6">
            <div className='mb-4'>
              <Input
                id="title"
                name="title"
                label="Request Title"
                error={errors?.title}
                type="text"
                maxLength={300}
                value={title}
                onChange={this.captureInput}
                tabIndex={1}
              />
            </div>

            <div className='mb-4'>
              <label className='form-label mb-0'>Description</label>
              <textarea style={{resize: 'none'}}
                rows="5"
                className="form-control" 
                id="description"
                name="description"
                maxLength={3000}
                error={errors?.description}
                value={description}
                onChange={this.captureInput}
                tabIndex={2}
              />
            </div>

            <div className='mb-4'>
              <Input
                id="expiration"
                name="expiration"
                label="Expiration"
                error={errors?.expiration}
                disabled={noExpCB || false}
                type="date"
                value={expiration}
                onChange={this.captureInput}
                tabIndex={3}
              />
            </div>

            <div className="form-check mb-3 mb-md-0 mt-md-2">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id="noExpCB" 
                checked={noExpCB || false} 
                onChange={() => this.checkBoxClicked('noExpCB')} />
              <label className="form-check-label" htmlFor={'noExpCB'}>
                No Expiration Date (Keep Praying Until Answered / Canceled)
              </label>
            </div>

            <div className="form-check mb-3 mb-md-0 mt-md-2 mb-4">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id="urgentCB" 
                checked={urgentCB || false} 
                onChange={() => this.checkBoxClicked('urgentCB')} />
              <label className="form-check-label" htmlFor={'urgentCB'}>
                Urgent Request (Mark as a High-Priority Prayer Request)
              </label>
            </div>

            <button className="btn btn-primary text-white mt-4" disabled={!title || !description || !(expiration || noExpCB)}
              onClick={() => this.sendRequest()}>
              {this.state.isProcessing ? 'Sending...' : 'Send Request'}
            </button>

            <SuccessMessage id="sucMsgSpan" className='mt-4'>{successMsg}</SuccessMessage>
            <ErrorMessage id="errMsgSpan" className='mt-2'>{errorMsg}</ErrorMessage>
          </div>
        </div>
      </div>
    )
  }
}
