import React, { Component } from 'react';
import { Input } from './UI/Inputs';
import axios from 'axios';
import LoginState from './LoginState';
import { ErrorMessage, SuccessMessage } from '../helpers/LayoutComponents';

export default class EditRequest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      requestor: '',
      title: '',
      description: '',
      successMsg: '',
      errorMsg: ''
    };

    this.GetPrayerRequest();
  }

  async GetPrayerRequest() {
    try {
      const response = axios.get('/FetchRequest', {
        params: { requestID: this.props.match.params.id }
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;
      this.setState({ 
        requestor: resultData.requestor, 
        title: resultData.title, 
        description: resultData.description, 
        expiration: resultData.expiration,
        noExpCB: resultData.expiration === "",
        urgentCB: resultData.isUrgent === "True",
        answeredCB: resultData.status === "Answered",
        canceledCB: resultData.status === "Canceled",
        fetching: false 
      });
    }
    catch {
      this.setState({ 
        requestor: '', 
        title: '', 
        description: '', 
        expiration: '',
        fetching: false 
      });
    }
  }

  async updateRequest() {
    try {
      const response = axios.get('/UpdatePrayerReq', {
        params: {
          idNum: this.props.match.params.id,
          title: this.state.title,
          description: this.state.description,
          expDate: this.state.expiration,
          isUrgent: this.state.urgentCB ? "1" : "0",
          isAnswered: this.state.answeredCB ? "1" : "0",
          isCanceled: this.state.canceledCB ? "1" : "0",
        },
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;

      if (resultData !== "Success") {
        this.setState({ errorMsg: resultData });
      }
      else {
        /* // todo: maybe redirect to request details page?
        this.setState({ 
          successMsg: 'Prayer Request Updated!',
          title: '',
          description: '',
          expiration: '',
          noExpCB: false,
          isUrgentCB: false
        }); */
        this.props.history.push("../success/upd");
      }
    }
    catch { this.setState({ errorMsg: 'Error!' }); }
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value, changesExist: true, successMsg: '', errorMsg: '' });
    console.log(this.state.expiration);
  };

  checkBoxClicked(targetName) {
    var checkedTest = this.state[targetName] ? this.state[targetName] : false;
    this.setState({
      [targetName]: !checkedTest,
      expiration: (targetName === "noExpCB" && checkedTest === false) ? '' : this.state.expiration
    });
  }

  render() {
    const {
      errors,
      title,
      description,
      expiration,
      noExpCB,
      urgentCB,
      answeredCB,
      canceledCB,
      successMsg,
      errorMsg
    } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <h1 className="h4">EDIT PRAYER REQUEST</h1>
          </div>
          
          <div className="col-12 col-md-6">
            <div className='mb-4'>
              <Input
                id="title"
                name="title"
                label="Request Title"
                error={errors?.title}
                type="text"
                maxLength={300}
                value={title}
                onChange={this.captureInput}
                tabIndex={1}
              />
            </div>

            <div className='mb-4'>
              <label className='form-label mb-0'>Description</label>
              <textarea style={{resize: 'none'}}
                rows="5"
                className="form-control" 
                id="description"
                name="description"
                maxLength={3000}
                error={errors?.description}
                value={description}
                onChange={this.captureInput}
                tabIndex={2}
              />
            </div>

            <div className='mb-4'>
              <Input
                id="expiration"
                name="expiration"
                label="Expiration"
                error={errors?.expiration}
                disabled={noExpCB || false}
                type="date"
                value={expiration}
                onChange={this.captureInput}
                tabIndex={3}
              />
            </div>

            <div className="form-check mb-3 mb-md-0 mt-md-2">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id="noExpCB" 
                checked={noExpCB || false} 
                onChange={() => this.checkBoxClicked('noExpCB')} />
              <label className="form-check-label" htmlFor={'noExpCB'}>
                No Expiration Date (Keep Praying Until Answered / Canceled)
              </label>
            </div>

            <div className="form-check mb-3 mb-md-0 mt-md-2 mb-4">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id="urgentCB" 
                checked={urgentCB || false} 
                onChange={() => this.checkBoxClicked('urgentCB')} />
              <label className="form-check-label" htmlFor={'urgentCB'}>
                Urgent Request (Mark as a High-Priority Prayer Request)
              </label>
            </div>

            <div className="form-check mb-3 mb-md-0 mt-md-2 mb-4">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id="answeredCB" 
                checked={answeredCB || false} 
                onChange={() => this.checkBoxClicked('answeredCB')} />
              <label className="form-check-label" htmlFor={'answeredCB'}>
                Mark Answered (Praise God!)
              </label>
            </div>

            <div className="form-check mb-3 mb-md-0 mt-md-2 mb-4">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id="canceledCB" 
                checked={canceledCB || false} 
                onChange={() => this.checkBoxClicked('canceledCB')} />
              <label className="form-check-label" htmlFor={'canceledCB'}>
                Cancel Request (Hide From Other Users)
              </label>
            </div>

            <button className="btn btn-primary text-white mt-4" disabled={!title || !description || !(expiration || noExpCB)}
              onClick={() => this.updateRequest()}>
              {this.state.isProcessing ? 'Updating...' : 'Update Request'}
            </button>

            <SuccessMessage id="sucMsgSpan" className='mt-4'>{successMsg}</SuccessMessage>
            <ErrorMessage id="errMsgSpan" className='mt-2'>{errorMsg}</ErrorMessage>
          </div>
        </div>
      </div>
    )
  }
}
